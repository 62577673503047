import { Grid, Typography, Button, Box, Modal, TextField } from '@mui/material';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: 400,
	bgcolor: 'background.paper',
	borderRadius: '5px',
	boxShadow: 24,
	padding: "16px 24px 8px 24px",
	alignItems: 'center',
};

export const ModalComment = (props) => {
	return (
		<Modal
			open={props.open}
			onClose={props.close}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: "12px", fontWeight: "700" }}>
					Комментарий дня
				</Typography>
				<Box
					component="form"
					noValidate
					autoComplete="off"
				>
					<TextField id="outlined-basic" label="Введите комментарий" variant="outlined" multiline rows={4} sx={{ width: "100%" }} />
					<Grid container sx={{ display: "flex", flexDirection: " row-reverse", mt: 1 }}>
						<Button sx={{ ml: 2 }}
							onClick={props.close}
							size="md"
							variant="contained"
						>Сохранить
						</Button>
						<Button
							onClick={props.close}
							size="md"
							variant="text"
						>Закрыть
						</Button>
					</Grid>
				</Box>
			</Box>
		</Modal>
	)
}
