import { TypeKeys } from '../actionTypes'

export const getMyResultsRequest = (query) => ({
  type: TypeKeys.GET_MYRESULTS_REQUEST,
  query
})

export const getMyResultsSuccess = (myResults) => ({
  type: TypeKeys.GET_MYRESULTS_SUCCESS,
  myResults
})

export const updateMyResultsRequest = (query) => ({
  type: TypeKeys.UPDATE_MYRESULTS_REQUEST,
  query
})

export const updateMyResultsSuccess = (myResults) => ({
  type: TypeKeys.UPDATE_MYRESULTS_SUCCESS,
  myResults
})
