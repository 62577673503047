import { all } from 'redux-saga/effects'
import { teamsSagas } from './teamsSaga'
import { companiesSagas } from './companiesSaga'
import { usersSagas } from './usersSaga'
import { authSagas } from './authSaga'
import { profileSagas } from './profileSaga'
import { userIndicatorsSagas } from './userIndicatorsSaga'
import { keyDatesSagas } from './keyDatesSaga'
import { userResultsSagas } from './userResultsSaga'
import { userRewardsSagas } from './userRewardsSaga'
import { reportsSagas } from './reportsSaga'
import { competitionsSagas } from './competitionsSaga'
import { teamUserIndicatorsSagas } from './teamUserIndicatorsSaga'
import { medalsSagas } from './medalsSaga'
import { awardTypesSagas } from './awardTypesSaga'
import { kpiChannelsSagas } from './kpiChannelsSaga'
import { performanceIndicatorsSagas } from './performanceIndicatorsSaga'
import { areasSagas } from './areasSaga'
import { divisionsSagas } from './divisionsSaga'
import { userStoriesSagas } from './userStoriesSaga'
import { guestsSagas } from './guestsSaga'
import { dashboardSagas } from './dashboardSaga'
import { newsfeedsSagas } from './newsfeedsSaga'
import { calendarsSagas } from './calendarsSaga'
import { checklistCategoriesSagas } from './checklistCategoriesSaga'
import { checklistsSagas } from './checklistsSaga'
import { taskTypesSagas } from './taskTypesSaga'
import { tasksSagas } from './tasksSaga'
import { integrationTokensSagas } from './integrationTokensSaga'
import { educationCategoriesSagas } from './educationCategoriesSaga'
import { educationsSagas } from './educationsSaga'
import { educationBlocksSagas } from './educationBlocksSaga'
import { educationTestsSagas } from './educationTestsSaga'
import { userEducationsSagas } from './userEducationsSaga'
import { userPositionsSagas } from './userPositionsSaga'
import { myResultsSagas } from './myResultsSaga'
import { myResultsTeamsSagas } from './myResultsTeamsSaga'

function* rootSaga() {
  yield all([
    ...authSagas,
    ...teamsSagas,
    ...companiesSagas,
    ...usersSagas,
    ...profileSagas,
    ...userIndicatorsSagas,
    ...keyDatesSagas,
    ...userResultsSagas,
    ...userRewardsSagas,
    ...reportsSagas,
    ...competitionsSagas,
    ...teamUserIndicatorsSagas,
    ...medalsSagas,
    ...awardTypesSagas,
    ...kpiChannelsSagas,
    ...performanceIndicatorsSagas,
    ...areasSagas,
    ...divisionsSagas,
    ...userStoriesSagas,
    ...guestsSagas,
    ...dashboardSagas,
    ...newsfeedsSagas,
    ...calendarsSagas,
    ...checklistCategoriesSagas,
    ...checklistsSagas,
    ...taskTypesSagas,
    ...tasksSagas,
    ...integrationTokensSagas,
    ...educationCategoriesSagas,
    ...educationsSagas,
    ...educationBlocksSagas,
    ...educationTestsSagas,
    ...userEducationsSagas,
    ...userPositionsSagas,
    ...myResultsSagas,
    ...myResultsTeamsSagas,
  ])
}

export default rootSaga
