import { combineReducers } from 'redux'
import teamsReducer from './teams'
import companiesReducer from './companies'
import teamsListReducer from './teamsList'
import teamReducer from './team'
import usersReducer from './users'
import profileReducer from './profile'
import userIndicatorsReducer from './userIndicators'
import keyDatesReducer from './keyDates'
import userResultsReducer from './userResults'
import userRewardsReducer from './userRewards'
import reportsReducer from './reports'
import competitionsReducer from './competitions'
import teamUserIndicatorsReducer from './teamUserIndicators'
import medalsReducer from './medals'
import awardTypesReducer from './awardTypes'
import kpiChannelsReducer from './kpiChannels'
import performanceIndicatorsReducer from './performanceIndicators'
import areasReducer from './areas'
import divisionsReducer from './divisions'
import userStoriesReducer from './userStories'
import guestsReducer from './guests'
import dashboardReducer from './dashboard'
import newsfeedsReducer from './newsfeeds'
import calendarsReducer from './calendars'
import checklistCategoriesReducer from './checklistCategories'
import checklistsReducer from './checklists'
import taskTypesReducer from './taskTypes'
import tasksReducer from './tasks'
import integrationTokensReducer from './integrationTokens'
import educationCategoriesReducer from './educationCategories'
import educationsReducer from './educations'
import educationTestsReducer from './educationTests'
import educationBlocksReducer from './educationBlocks'
import userEducationsReducer from './userEducations'
import userPositionsReducer from './userPositions'
import myResultsReducer from './myResults'
import myResultsTeamsReducer from './myResultsTeams'
import snackbarReducer from './snackbars'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export const rootReducer = (history) => combineReducers({
  teamsReducer,
  companiesReducer,
  teamReducer,
  usersReducer,
  profileReducer,
  userIndicatorsReducer,
  keyDatesReducer,
  userResultsReducer,
  userRewardsReducer,
  reportsReducer,
  teamsListReducer,
  competitionsReducer,
  teamUserIndicatorsReducer,
  medalsReducer,
  awardTypesReducer,
  kpiChannelsReducer,
  performanceIndicatorsReducer,
  areasReducer,
  divisionsReducer,
  userStoriesReducer,
  guestsReducer,
  dashboardReducer,
  newsfeedsReducer,
  calendarsReducer,
  checklistCategoriesReducer,
  checklistsReducer,
  taskTypesReducer,
  tasksReducer,
  integrationTokensReducer,
  educationCategoriesReducer,
  educationsReducer,
  educationTestsReducer,
  educationBlocksReducer,
  userEducationsReducer,
  userPositionsReducer,
  myResultsReducer,
  myResultsTeamsReducer,
  snackbarReducer,
})
