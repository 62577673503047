import { Grid, Typography, TextField, Button, Box, Divider, Modal } from '@mui/material'
import { makeStyles } from '@mui/styles';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: 400,
	bgcolor: 'background.paper',
	borderRadius: '5px',
	boxShadow: 24,
	padding: "16px 24px 8px 24px",
	alignItems: 'center',
};

const useStyles = makeStyles(() => ({
	disabledInputBlack: {
		"& .MuiInputBase-input.Mui-disabled": {
			WebkitTextFillColor: "#000000",
		},
		"& .MuiInputBase-label.Mui-disabled": {
			WebkitTextFillColor: "#000000",
		},
	},
}));

export const ModalKpi = (props) => {
	const classes = useStyles()
	return (
		<Modal
			open={props.open}
			onClose={() => { props.close(); props.setModalDataKpi(null); }}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography variant="h5" component="h2" sx={{ my: 1, fontWeight: "700" }}>
					Название KPI
				</Typography>
				{props.result.child_results.map((child_result, index) => {
					return (
						<Grid container key={child_result.id}>
							<div>
								<Typography id="modal-modal-title" variant="subtitle1" component="h2" sx={{ color: "#9196A8", borderBottom: "white" }}>
									{child_result.kpi_title}
								</Typography>
								<TextField disabled={true} value={child_result.plan} id="standard-basic" label="План"
									variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ style: { color: 'rgba(0, 0, 0, 0.6)' }, }}
									sx={{ width: "100px", mr: 2 }} className={classes.disabledInputBlack}
								/>
								<TextField id="standard-basic" label="Факт" value={child_result.value} onChange={props.childChangeValue(child_result.id)} variant="standard" InputProps={{ disableUnderline: true }} sx={{ width: "100px", mr: 2 }} />
								<TextField id="outlined-basic"
									label="Комментарий"
									value={child_result.story.description === "Комментарий отсутствует." ? "" : child_result.story.description}
									onChange={props.childChangeComment(child_result.id)}
									variant="outlined"
									size="small"
									sx={{ minWidth: "316px", mt: 1 }} />
							</div>
							{index !== props.result.child_results.length - 1 && <Divider sx={{ my: 2 }} />}
						</Grid>
					)
				})}
				<Grid container sx={{ display: "flex", flexDirection: " row-reverse", mt: 1 }}>
					<Button sx={{ ml: 2 }}
						onClick={props.close}
						size="md"
						variant="contained"
					>Сохранить
					</Button>
					<Button
						onClick={() => { props.close(); props.setModalDataKpi(null); }}
						size="md"
						variant="text"
					>Закрыть
					</Button>
				</Grid>
			</Box>
		</Modal>
	)
}
