import React from "react";
import { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { createReportRequest } from "../../actions/actionCreators/reportsActions"
import TeamSelect from "../../components/Main/TeamSelect"
import CompetitionSelect from '../../components/Main/competitionSelect'
import moment from 'moment'
import 'moment/locale/ru'

import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import clsx from 'clsx';

import { Paper, Grid, Typography, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const NewReportForm = ({ companies, createReport }) => {

  const [reportType, setReportType] = useState({value: ""})
  const [company, setCompany] = useState({value: ""})
  const [team, setTeam] = useState({value: ""})
  const [competition, setCompetition] = useState({value: ""})


  const [month, setMonth] = useState({value: ""})

  const [file, setFile] = useState(null)

  moment.locale('ru')

  const changeReportType = (e, value) => {
    setReportType({value: value.props.value, label: value.props.label})
    setCompany({value: ""})
    setTeam({value: ""})
    setMonth({value: ""})
  }
  const changeSelectedCompany = (e, value) => {
    setCompany({value: value.props.value, label: value.props.label})
    setTeam({value: ""})
    setCompetition({value: ""})
    setMonth({value: ""})
  }
  const changeSelectedTeam = (e) => setTeam(e)
  const changeSelectedCompetition = (e) => setCompetition(e)
  const changeMonth = (e, value) => setMonth({value: value.props.value, label: value.props.label})

  const options = [
    { value: 'active_users', label: 'Отчет о количестве пользователей'},
    { value: 'team_tree', label: 'Отчет по составу команд' },
    { value: 'closed_dates', label: 'Отчет по закрытию дня'},
    { value: 'kpi_results', label: 'Отчет по KPI'},
    { value: 'competition_results', label: 'Отчет по акциям'},
    { value: 'team_plans', label: 'Отчет по выставлению планов'},
    { value: 'awards', label: 'Отчет по наградам'},
    { value: 'social_activity', label: 'Отчет по социальной активности'},
    { value: 'team_social_activity', label: 'Отчет по социальной активности по командам'},
    { value: 'stories', label: 'Отчет по историям'},
    { value: 'empty_results', label: 'Отчет по нулевым результатам'},
    { value: 'results_efficiency', label: 'Отчет по эффективности'},
    { value: 'group_chats', label: 'Отчет по групповым чатам'},
    { value: 'tasks', label: 'Отчет по задачам'},
    { value: 'advanced_tasks', label: 'Отчет по задачам (раcширенный)'},
    { value: 'geopoints', label: 'Отчет по локациям'},
    { value: 'checklists', label: 'Отчет по чеклистам'},
    { value: 'educations', label: 'Отчет по обучению'},
    { value: 'deals', label: 'Отчет по сделкам'},
    { value: 'results', label: 'Отчет по результатам'}
  ]

  const months = [...Array(12).keys()].map((m) => {
    return {
      value: moment().subtract(m,"month").format("YYYY-MM-DD"),
      label: moment().subtract(m,"month").format("MMMM YYYY")
    }
  })

  const mapCompanies = (cs) => {
    return cs.map((option, index) => <MenuItem key={index} value={option.id} label={option.title}>{option.title}</MenuItem> )
  }

  const createReportRequest = () => {
    const response = createReport(
      {
        data: {
          report_type: reportType.value,
          report_params: {
            team_id: team.value,
            team_title: team.value ? team.label : null,
            company_id: company.value,
            company_title: company.value ? company.label : null,
            competition_id: competition.value,
            competition_title: competition.value ? competition.label : null,
            month_id: month.value,
            month_title: month.value ? month.label : null
          }
        }
      }
    )
  }

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const showTeamSelect = (reportType) => {
    return ["empty_results", "closed_dates", "kpi_results",
            "team_tree", "team_plans", "awards", "results_efficiency",
            "team_social_activity", "advanced_tasks", "tasks", "geopoints", "stories", "deals", "results"].includes(reportType)
  }

  const showMonthSelect = (reportType, team) => {
    return ((["kpi_results", "closed_dates", "empty_results",
              "team_social_activity", "advanced_tasks", "tasks", "geopoints", "stories", "deals", "results"].includes(reportType) && team !== "") ||
            (["active_users", "results_efficiency", "group_chats", "checklists", "educations"].includes(reportType)))
  }

  const showCreateButton = (reportType, month, team, competition) => {
    return (["active_users", "group_chats", "checklists", "educations"].includes(reportType) && month !== "") ||
    (["team_tree", "team_plans", "awards"].includes(reportType) && team !== "") ||
    (["kpi_results", "closed_dates", "empty_results", "results_efficiency",
      "team_social_activity", "advanced_tasks", "tasks", "geopoints", "stories", "deals", "results"].includes(reportType) && team !== "" && month !== "") ||
    (["competition_results"].includes(reportType) && competition !== "") ||
    (["social_activity"].includes(reportType))
  }

  return (
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Typography variant="h5">Сформировать отчет</Typography>
      </Grid>
      <Grid item sm={12}>
        <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Тип отчета</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={reportType.value}
            onChange={changeReportType}
            label="Тип отчета"
          >
          { options.map((option, index) => <MenuItem key={index} value={option.value} label={option.label}>{option.label}</MenuItem>) }
          </Select>
        </FormControl>
      </Grid>
      { reportType.value !== "" &&
        <Grid item sm={12}>
          <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}
            >
            <InputLabel id="demo-simple-select-outlined-label">Компания</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={company.value}
              onChange={changeSelectedCompany}
              label="Компания"
            >
            { mapCompanies(companies) }
            </Select>
          </FormControl>
        </Grid>}
      { (company.value !== "" && showTeamSelect(reportType.value)) &&
        <Grid item sm={12}>
          <TeamSelect companyIds={[company.value]} changeTeam={changeSelectedTeam} value={team.value}/>
        </Grid>}
      { (company.value !== "" && reportType.value  === "competition_results") &&
        <Grid item sm={12}>
          <CompetitionSelect companyId={company.value} changeCompetition={changeSelectedCompetition} value={competition.value}/>
        </Grid>}
      { (company.value !== "" && showMonthSelect(reportType.value, team.value)) &&
        <Grid item sm={12}>
          <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}
            >
            <InputLabel id="demo-simple-select-outlined-label">Месяц</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={month.value}
              onChange={changeMonth}
              label="Месяц"
            >
            { months.map((option, index) => <MenuItem key={index} value={option.value} label={option.label}>
                {option.label.charAt(0).toUpperCase() + option.label.slice(1)}
              </MenuItem>) }
            </Select>
          </FormControl>
        </Grid> }
      {reportType.value !== "" && company.value !== "" &&
        showCreateButton(reportType.value, month.value, team.value, competition.value) &&
        <Grid item sm={12}>
          <Button variant="contained" size="small" color="primary" onClick={createReportRequest}>Сформировать</Button>
        </Grid> }
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  createReport: (query) => dispatch(createReportRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewReportForm)
