import { initialMyResults } from "../initial/initialMyResults"
import { TypeKeys } from "../actions/actionTypes"

export const myResultsReducer = (state = initialMyResults(), action) => {
  switch (action.type) {
    case TypeKeys.GET_MYRESULTS_REQUEST:
      return { ...state, myResults: {}, loading: true }
    case TypeKeys.GET_MYRESULTS_SUCCESS:
      return { ...state, myResults: action.myResults, loading: false }
    case TypeKeys.UPDATE_MYRESULTS_REQUEST:
      return { ...state, myResults: {}, loading: true }
    case TypeKeys.UPDATE_MYRESULTS_SUCCESS:
      return { ...state, myResults: action.myResults, loading: false }
    default:
      return { ...state }
  }
}

export default myResultsReducer