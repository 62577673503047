import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getMyResultsSuccess, updateMyResultsSuccess }
	from '../actions/actionCreators/myResultsActions'
import { showSnackbar } from '../actions/actionCreators/snackbarAction'
import callApi from '../utils/callApi'
import config from '../config'
import { v4 as uuidv4 } from 'uuid'

function parseMyResults(myResults) {
	return {
		...myResults,
		results: myResults.results.map((result) => {
			return {
				...result,
				id: result.id || uuidv4(),
				story: result.story || {},
				child_results: result.child_results.map((childResult) => {
					return {
						...childResult,
						id: childResult.id || uuidv4(),
						story: childResult.story || {},
					}
				})
			}
		}
		)
	}
}

function* loadMyResults(action) {
	const res = yield call(callApi, `${config.BASE_URL}/api/v1/teams/${action.query.teamId}/my_results?date=${action.query.date}`)
	yield put(getMyResultsSuccess(parseMyResults(res.data)))
}

function* updateMyResults(action) {
	try {
		const res = yield call(callApi,
			`${config.BASE_URL}/api/v1/teams/${action.query.teamId}/my_results`,
			{ method: 'POST', data: action.query.data });
		yield put(updateMyResultsSuccess(res.data));
		yield put(showSnackbar(true, 'Результаты успешно обновлены', 'success'));
	} catch (error) {
		yield put(showSnackbar(true, 'Ошибка при обновлении результатов', 'error'));
	}
}

export const myResultsSagas = [
	takeLatest([TypeKeys.GET_MYRESULTS_REQUEST], loadMyResults),
	takeLatest([TypeKeys.UPDATE_MYRESULTS_REQUEST], updateMyResults),
]
