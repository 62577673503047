import { initialSnackbar } from "../initial/initialSnackbars"
import { TypeKeys } from "../actions/actionTypes"

const snackbarReducer = (state = initialSnackbar, action) => {
	switch (action.type) {
		case TypeKeys.SHOW_SNACKBAR:
			return { ...state, open: true, message: action.message, severity: action.severity };
		case TypeKeys.HIDE_SNACKBAR:
			return { ...state, open: false, message: '' };
		default:
			return state;
	}
};

export default snackbarReducer;