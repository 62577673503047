import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getMyResultsTeamsSuccess, updateMyResultsTeamsSuccess }
	from '../actions/actionCreators/myResultsTeamsActions'
import callApi from '../utils/callApi'
import config from '../config'

function* loadMyResultsTeams() {
	const res = yield call(callApi, `${config.BASE_URL}/api/v1/teams/`)
	yield put(getMyResultsTeamsSuccess(res.data))
}

function* updateMyResultsTeams(action) {
	const res = yield call(callApi,
		`${config.BASE_URL}/admin/api/v1/teams`,
		{ method: 'PUT', data: {data: action.query.data }})
	yield put(updateMyResultsTeamsSuccess(res.data))
}

export const myResultsTeamsSagas = [
	takeLatest([TypeKeys.GET_MYRESULTSTEAMS_REQUEST], loadMyResultsTeams),
	takeLatest([TypeKeys.UPDATE_MYRESULTSTEAMS_REQUEST], updateMyResultsTeams),
]
