import { Grid, Typography, TextField, Button, Box, Modal } from '@mui/material'

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: 400,
	bgcolor: 'background.paper',
	borderRadius: '5px',
	boxShadow: 24,
	padding: "16px 24px 8px 24px",
	alignItems: 'center',
};

export const ModalKpiComment = (props) => {
	return (
		<Modal
			open={props.open}
			onClose={() => { props.close(); props.setModalDataKpiComment(null); }}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: "12px", fontWeight: "700" }}>
					{props.result.kpi_title}
				</Typography>
				<TextField id="outlined-basic"
					value={props.result.story.description === "Комментарий отсутствует." ? "" : props.result.story.description}
					onChange={props.changeComment(props.result.id)}
					placeholder={"Комментарий отсутствует."}
					variant="outlined" multiline rows={4}
					sx={{ width: "100%" }} />
				<Grid container sx={{ display: "flex", flexDirection: " row-reverse", mt: 1 }}>
					<Button sx={{ ml: 2 }}
						onClick={props.close}
						size="md"
						variant="contained"
					>Сохранить
					</Button>
					<Button
						onClick={() => { props.close(); props.setModalDataKpiComment(null); }}
						size="md"
						variant="text"
					>Закрыть
					</Button>
				</Grid>
			</Box>
		</Modal>
	)
}
