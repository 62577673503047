import { TypeKeys } from '../actionTypes'

export const showSnackbar = (open, message, severity) => ({
	type: TypeKeys.SHOW_SNACKBAR,
	open, message, severity,
})

export const hideSnackbar = () => ({
	type: TypeKeys.HIDE_SNACKBAR,
})
