import React, { Component } from 'react'

import { Routes, Route, Navigate } from 'react-router-dom'

import * as Page from './pages'

export const RootRouter = props => {
	return (
		<Routes>
			<Route path='/login' element={<Page.SignIn />} />
			<Route element={<Page.AppLayout />}>
				<Route index element={<Page.Dashboard />} />
				{/* // Teams */}
				<Route path='/teams' element={<Page.TeamsPanel />} />
				<Route path='/teams/:id' element={<Page.TeamDescriptionPanel />} />
				<Route path='/teams/:id/edit' element={<Page.TeamEdit />} />
				<Route path='/teams/:id/:page' element={<Page.TeamDescriptionPanel />} />
				<Route
					path='/teams/:teamId/performance_indicators/:performanceIndicatorId'
					element={<Page.TeamUserIndicatorsPanel />}
				/>
				<Route path='/teams/:teamId/guests/:guestId' element={<Page.GuestEdit />} />
				{/* // Dashboard */}
				<Route path='/dashboard' element={<Page.Dashboard />} />
				<Route path='/dashboard/:teamId/closed_dates' element={<Page.ClosedDaysDashboard />} />
				<Route path='/dashboard/:teamId/plans_updated' element={<Page.PlansUpdatedDashboard />} />
				<Route path='/dashboard/:teamId/plan_value' element={<Page.PlanValueDashboard />} />
				<Route path='/dashboard/:teamId/plan_complete' element={<Page.PlanCompleteDashboard />} />
				<Route path='/dashboard/:teamId/zero_results' element={<Page.ZeroResultsDashboard />} />
				<Route
					path='/dashboard/:teamId/zero_teams_results'
					element={<Page.ZeroTeamsResultsDashboard />}
				/>
				<Route path='/dashboard/:teamId/results_loss' element={<Page.ResultsLossDashboard />} />
				<Route
					path='/dashboard/:teamId/social_activity'
					element={<Page.SocialActivityDashboard />}
				/>
				<Route path='/dashboard/:teamId/team_summary' element={<Page.TeamSummaryDashboard />} />
				<Route path='/dashboard/:teamId/task_stats' element={<Page.TaskStatsDashboard />} />
				<Route path='/dashboard/:teamId/task_team_stats' element={<Page.Checklists />} />
				<Route path='/dashboard/:teamId/showcase' element={<Page.Showcase />} />
				<Route path='/dashboard/:teamId/checklists' element={<Page.Checklists />} />
				<Route path='/dashboard/:teamId' element={<Page.TeamIndexPanel />} />
				{/* // Updload Files */}
				<Route path='/upload_files' element={<Page.UploadFiles />} />
				{/* // Newsfeeds */}
				<Route path='/newsfeeds' element={<Page.NewsfeedsPanel />} />
				<Route path='/newsfeeds/new' element={<Page.NewMessageForm />} />
				{/* // Medals */}
				<Route path='/medals' element={<Page.MedalsPanel />} />
				<Route path='/medals/:id' element={<Page.MedalEdit />} />
				{/* // Award types */}
				<Route path='/award_types' element={<Page.AwardTypesPanel />} />
				<Route path='/award_types/new' element={<Page.AwardTypeNewPanel />} />
				<Route path='/award_types/history' element={<Page.AwardTypesHistory />} />
				<Route path='/award_types/:id' element={<Page.AwardTypeEditPanel />} />
				{/* // Kpi channels */}
				<Route path='/kpi_channels' element={<Page.KpiChannelsPanel />} />
				<Route path='/kpi_channels/new' element={<Page.KpiChannelNewPanel />} />
				<Route path='/kpi_channels/:id' element={<Page.KpiChannelEditPanel />} />
				{/* // Integration Tokens IntegrationTokensPanel */}
				<Route path='/integration_tokens' element={<Page.IntegrationTokensPanel />} />
				<Route path='/integration_tokens/new' element={<Page.IntegrationTokenNewPanel />} />
				<Route path='/integration_tokens/:id' element={<Page.IntegrationTokenEditPanel />} />
				<Route path='/integration_tokens/:id/history' element={<Page.IntegrationTokenHistory />} />
				{/* // User positions */}
				<Route path='/user_positions' element={<Page.UserPositionsPanel />} />
				<Route path='/user_positions/new' element={<Page.UserPositionNewPanel />} />
				<Route path='/user_positions/:id' element={<Page.UserPositionEditPanel />} />
				{/* // Areas */}
				<Route path='/areas' element={<Page.AreasPanel />} />
				<Route path='/areas/new' element={<Page.AreaNewPanel />} />
				<Route path='/areas/:id' element={<Page.AreaEditPanel />} />
				{/* // Task types */}
				<Route path='/task_types' element={<Page.TaskTypesPanel />} />
				<Route path='/task_types/new' element={<Page.TaskTypeNewPanel />} />
				<Route path='/task_types/:id' element={<Page.TaskTypeEditPanel />} />
				{/* // Divisions */}
				<Route path='/divisions' element={<Page.DivisionsPanel />} />
				<Route path='/divisions/new' element={<Page.DivisionNewPanel />} />
				<Route path='/divisions/:id' element={<Page.DivisionEditPanel />} />
				{/* // Companies */}
				<Route path='/companies' element={<Page.CompaniesPanel />} />
				<Route path='/companies/new' element={<Page.CompaniesNew />} />
				<Route path='/companies/:id' element={<Page.CompanyEdit />} />
				{/* // Users */}
				<Route path='/users' element={<Page.UsersPanel />} />
				<Route path='/users/new' element={<Page.UserNew />} />
				<Route path='/users/:id/edit' element={<Page.UserEdit />} />
				<Route path='/users/:id' element={<Page.UserDescription />} />
				{/* // Results */}
				<Route path='/results' element={<Page.MyResultsPanel />} />
				{/* // Reports */}
				<Route path='/reports' element={<Page.ReportsPanel />} />
				{/* // Tasks */}
				<Route path='/tasks' element={<Page.TasksPanel />} />
				<Route path='/tasks/new' element={<Page.TaskNewPanel />} />
				<Route path='/tasks/:id' element={<Page.TaskDescription />} />
				<Route path='/tasks/:id/edit' element={<Page.TaskEditPanel />} />
				{/* // ChecklistCategories */}
				<Route path='/checklist_categories' element={<Page.ChecklistCategoriesPanel />} />
				<Route path='/checklist_categories/new' element={<Page.ChecklistCategoryNewPanel />} />
				<Route path='/checklist_categories/:id' element={<Page.ChecklistCategoryPanel />} />
				<Route
					path='/checklist_categories/:checklistCategoryId/checklists/new'
					element={<Page.ChecklistNew />}
				/>
				<Route
					path='/checklist_categories/:checklistCategoryId/checklists/:id'
					element={<Page.ChecklistEdit />}
				/>
				{/* // EducationCategories */}
				<Route path='/education_categories' element={<Page.EducationCategoriesPanel />} />
				<Route path='/education_categories/new' element={<Page.EducationCategoryNewPanel />} />
				<Route
					path='/education_categories/:id/edit'
					element={<Page.EducationCategoryEditPanel />}
				/>
				<Route path='/education_categories/:id' element={<Page.EducationCategoryPanel />} />
				{/* // Educations */}
				<Route
					path='/education_categories/:educationCategoryId/educations/:id'
					element={<Page.EducationPanel />}
				/>
				<Route
					path='/education_categories/:educationCategoryId/educations/new'
					element={<Page.EducationNewForm />}
				/>
				<Route
					path='/education_categories/:educationCategoryId/educations/:id/edit'
					element={<Page.EducationEditForm />}
				/>
				{/* // Education blocks */}
				<Route
					path='/education_categories/:educationCategoryId/educations/:educationId/education_blocks/new'
					element={<Page.EducationBlockNewForm />}
				/>
				<Route
					path='/education_categories/:educationCategoryId/educations/:educationId/education_blocks/:id'
					element={<Page.EducationBlockEditForm />}
				/>
				{/* // Education tests */}
				<Route
					path='/education_categories/:educationCategoryId/educations/:educationId/education_tests/new'
					element={<Page.EducationTestNewForm />}
				/>
				<Route
					path='/education_categories/:educationCategoryId/educations/:educationId/education_tests/:id'
					element={<Page.EducationTestEditForm />}
				/>
				{/* // UserEducations */}
				<Route path='/user_educations' element={<Page.UserEducationsPanel />} />
				<Route path='/user_educations/:id' element={<Page.UserEducationPanel />} />
				<Route path='/assign_user_educations' element={<Page.MassAssign />} />

				{/* // 404 */}
				<Route element={<Page.NotFound />} />
			</Route>
		</Routes>
	)
}
