import React, { useCallback, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { NavLink } from 'react-router-dom'
import { To } from 'react-router'
import {
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	Box,
	Collapse,
} from '@mui/material'
import {
	Group,
	Person,
	Assessment,
	Business,
	EmojiEvents,
	ExitToApp,
	ExpandLess,
	ExpandMore,
	List as ListIcon,
	DynamicFeed as DynamicFeedIcon,
	TrendingUp as TrendingUpIcon,
	Assignment as AssignmentIcon,
	CloudSync as CloudSyncIcon,
	School as SchoolIcon,
	FileDownload,
} from '@mui/icons-material'
import { clearToken } from '../../utils/getToken'
import UserBlock from './UserBlock'
import FactCheckIcon from '@mui/icons-material/FactCheck';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	active: {
		backgroundColor: 'red',
	},
}))

const MenuLink = ({ to, Icon, label }) => (
	<NavLink to={to} style={{ textDecoration: 'none', color: 'inherit' }}>
		{({ isActive }) => (
			<ListItem selected={isActive}>
				{Icon && (
					<ListItemIcon>
						<Icon />
					</ListItemIcon>
				)}
				<ListItemText primary={label} sx={{ pl: Icon ? 0 : 3 }} />
			</ListItem>
		)}
	</NavLink>
)

export default function NavigationPanel({ profile }) {
	const [openCodebooks, setOpenCodebooks] = useState(false)
	const toggleCodebooks = useCallback(() => setOpenCodebooks(value => !value), [])

	const [openEducation, setOpenEducation] = useState(false)
	const toggleEducation = useCallback(() => setOpenEducation(value => !value), [])

	if (!profile.user) return null
	return (
		<div>
			<Divider />
			{/* ------------------------------------------------------------ */}
			{(profile.role === 'super_admin' || profile.role === 'admin') && (
				<>
					<List>
						<MenuLink to='/teams' Icon={Group} label='Команды' />

						<MenuLink to='/users' Icon={Person} label='Пользователи' />
						<MenuLink to='/reports' Icon={Assessment} label='Отчеты' />
						<MenuLink to='/tasks' Icon={AssignmentIcon} label='Задачи' />
						<ListItem button onClick={toggleCodebooks}>
							<ListItemIcon>
								<ListIcon />
							</ListItemIcon>
							<ListItemText primary='Справочники' />
							{openCodebooks ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={openCodebooks} timeout='auto' unmountOnExit>
							<List component='div' disablePadding>
								<MenuLink to='/award_types' label='Типы наград' />
								<MenuLink to='/kpi_channels' label='Каналы KPI' />
								<MenuLink to='/areas' label='Эрии участников' />
								<MenuLink to='/divisions' label='Эрии руководителей' />
								<MenuLink to='/checklist_categories' label='Категории чек-листов' />
								<MenuLink to='/task_types' label='Типы задач' />
								<MenuLink to='/user_positions' label='Должности' />
							</List>
						</Collapse>
						<MenuLink to='/integration_tokens' Icon={CloudSyncIcon} label='Токены интеграции' />
						<ListItem button onClick={toggleEducation}>
							<ListItemIcon>
								<SchoolIcon />
							</ListItemIcon>
							<ListItemText primary='Обучение' />
							{openEducation ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={openEducation} timeout='auto' unmountOnExit>
							<List component='div' disablePadding>
								<MenuLink to='/education_categories' label='Курсы' />
								<MenuLink to='/user_educations' label='Прохождение' />
								<MenuLink to='/assign_user_educations' label='Назначить' />
							</List>
						</Collapse>
						<MenuLink to='/newsfeeds' Icon={DynamicFeedIcon} label='Сообщения в ленту' />
					</List>
					<Divider />
					{/* ------------------------------------------------------------ */}
				</>
			)}

			<List>
				<MenuLink to='/results' Icon={FactCheckIcon} label='Результаты' />
				<MenuLink to='/dashboard' Icon={TrendingUpIcon} label='Аналитика' />
				<MenuLink to='/upload_files' Icon={FileDownload} label='Загрузка файлов' />
				<MenuLink to='/tasks' Icon={AssignmentIcon} label='Задачи' />
			</List>
			<Divider />
			{/* ------------------------------------------------------------ */}

			{profile.role === 'super_admin' && (
				<React.Fragment>
					<List>
						<MenuLink to='/companies' Icon={Business} label='Компании' />
						<MenuLink to='/medals' Icon={EmojiEvents} label='Медали' />
					</List>
					<Divider />
				</React.Fragment>
			)}
			<Box m={2}>
				<Typography color='textSecondary' variant='body2'>
					<strong>Пользователь:</strong>
				</Typography>
				{profile.user && <UserBlock user={profile.user} role={profile.role} />}
			</Box>
			<Divider />
			{/* ------------------------------------------------------------ */}
			<Box m={2}>
				<Typography color='textSecondary' variant='body2'>
					<strong>Доступные компании:</strong> <br />
					{profile.companies.map(company => company.title).join(', ')}
				</Typography>
			</Box>
			<Divider />
			{/* ------------------------------------------------------------ */}
			<List>
				<ListItem button onClick={clearToken}>
					<ListItemIcon>
						<ExitToApp />
					</ListItemIcon>
					<ListItemText primary='Выход' />
				</ListItem>
			</List>
		</div>
	)
}
