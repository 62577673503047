import { TypeKeys } from '../actionTypes'

export const getMyResultsTeamsRequest = (query) => ({
	type: TypeKeys.GET_MYRESULTSTEAMS_REQUEST,
	query
})

export const getMyResultsTeamsSuccess = (myResultsTeams) => ({
	type: TypeKeys.GET_MYRESULTSTEAMS_SUCCESS,
	myResultsTeams
})

export const updateMyResultsTeamsRequest = (query) => ({ 
	type: TypeKeys.UPDATE_MYRESULTSTEAMS_REQUEST, 
	query 
})

export const updateMyResultsTeamsSuccess = (myResultsTeams) => ({
	type: TypeKeys.UPDATE_MYRESULTSTEAMS_SUCCESS,
	myResultsTeams
})
