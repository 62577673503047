import React from "react";
import { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { ModalKpi } from './MyResultsPanelModalKpi';
import { ModalKpiComment } from './MyResultPanelModalKpiComment';
import { ModalComment } from './MyResultsPanelModalComment'
import { ModalMessage } from './MyResultsPanelModalMessage'

import { getMyResultsRequest, updateMyResultsRequest } from "../../actions/actionCreators/myResultsActions"
import { getMyResultsTeamsRequest, updateMyResultsTeamsRequest } from "../../actions/actionCreators/myResultsTeamsActions"
import { showSnackbar, hideSnackbar } from "../../actions/actionCreators/snackbarAction"
// mui
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Typography, TextField, Button, Box, Divider } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ArrowForward from '@mui/icons-material/ArrowForward';
// datepicker
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },
  numberCircle: {
    borderRadius: "50%",
    width: "16px",
    height: "16px",
    background: "#92D047",
    border: "2px solid #92D047",
    color: "white",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "13px",
    marginLeft: "24px",
    marginTop: "8px",
  },
  disabledInputBlack: {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000",
    },
    "& .MuiInputBase-label.Mui-disabled": {
      WebkitTextFillColor: "#000000",
    },
  },
  buttonContainerMedia: {
    '@media (max-width: 1320px)': {
      display: "flex",
      flexGrow: 0,
    },
    '@media (max-width: 900px)': {
      flexDirection: "column !important",
      alignItems: "center",
    },
  },
  buttonMedia: {
    '@media (max-width: 1320px)': {
      width: "30vw",
      marginRight: "2px !important",
    },
    '@media (max-width: 900px)': {
      width: "100%",
      margin: "6px 0 !important",
    },
  },
  selectContainerMedia: {
    '@media (max-width: 790px)': {
      margin: "0",
    },
    '@media (max-width: 650px)': {
      margin: "16px 0 0 0 !important",
      flexDirection: "column",
    },
  },
  selectMedia: {
    '@media (max-width: 650px)': {
      width: "78vw !important",
    },
  },
  buttonsContainerMedia: {
    '@media (max-width: 650px)': {
      margin: "16px 0 0 0 !important",
    },
    '@media (max-width: 470px)': {
      margin: "16px 0 0 0 !important",
    },
  },
  inputsContainerMedia: {
    '@media (max-width: 600px)': {
      flexDirection: "column !important",
    },
  },
  awardMedia: {
    '@media (max-width: 600px)': {
      margin: "0 !important",
      alignSelf: "start !important",
    },
  },
  resultsContainerMedia: {
    '@media (max-width: 650px)': {
      flexDirection: "column !important",
    },
  },
}));

const MyResultsPanel = (props) => {
  const { myResults, myResultsTeams, snackbar } = props;
  const classes = useStyles();

  const [team, setTeam] = React.useState('');
  const [date, setDate] = React.useState(moment().toDate());
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const [openCommentModal, setOpenCommentModal] = React.useState(false);
  const handleCloseCommentModal = () => setOpenCommentModal(false);
  const [openCommentKpiModal, setOpenCommentKpiModal] = React.useState(false);
  const handleCloseCommentKpiModal = () => setOpenCommentKpiModal(false);
  const [openMessageModal, setOpenMessageModal] = React.useState(false);
  const handleCloseMessageModal = () => setOpenMessageModal(false);
  const [openKpiModal, setOpenKpiModal] = useState(false);
  const handleCloseKpiModal = () => setOpenKpiModal(false);

  const [modalDataKpi, setModalDataKpi] = useState(null);
  const [modalDataKpiComment, setModalDataKpiComment] = useState(null);
  const [modalDataMessage, setModalDataMessage] = useState(null);

  const [localResults, setLocalResults] = useState({ results: [], competitions: [], messages: [] });
  const [localResultsTeams, setLocalResultsTeams] = useState([]);

  useEffect(() => {
    props.loadMyResultsTeams({});
  }, []);

  useEffect(() => {
    const filteredTeams = myResultsTeams.filter((team) => team.viewer_role === "member");
    setLocalResultsTeams(filteredTeams);
  }, [myResultsTeams]);

  useEffect(() => {
    setLocalResults(myResults);
  }, [myResults]);

  useEffect(() => {
    if (team !== '') {
      props.loadMyResults({ teamId: team, date: date });
    }
  }, [team, date]);

  useEffect(() => {
    if (snackbar.open) {
      setOpenSnackbar(true);
      setTimeout(() => {
        props.hideSnackbar()
      },
        5000);
    }
  }, [snackbar.open]);

  const handleChangeTeam = (event) => {
    const selectedTeam = Number(event.target.value);
    setTeam(selectedTeam);
  };

  const handleChangeDate = (newDate) => {
    const formattedDate = moment(newDate).format('YYYY-MM-DD');
    setDate(formattedDate);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const resultChangeHandler = (id, key) => {
    return (e) => {
      setLocalResults({
        ...localResults,
        results: localResults.results.map((result) => {
          if (result.id === id) {
            result[key] = Number(e.target.value)
          }
          return result
        })
      })
    }
  };

  const resultChildValueChangeHandler = (id) => {
    return (e) => {
      setLocalResults({
        ...localResults,
        results: localResults.results.map((result) => {
          result.child_results = result.child_results.map((child_result) => {
            if (child_result.id === id) {
              child_result.value = Number(e.target.value)
            }
            return child_result
          })
          return result
        })
      })
    }
  };

  const resultChildCommentChangeHandler = (id) => {
    return (e) => {
      setLocalResults({
        ...localResults,
        results: localResults.results.map((result) => {
          result.child_results = result.child_results.map((child_result) => {
            if (child_result.id === id) {
              child_result.story.description = e.target.value
            }
            return child_result
          })
          return result
        })
      })
    }
  };

  const commentChangeHandler = (id) => {
    return (e) => {
      setLocalResults({
        ...localResults,
        results: localResults.results.map((result) => {
          if (result.id === id) {
            result.story.description = e.target.value
          }
          return result
        })
      })
    }
  };

  const competitionChangeHandler = (id) => {
    return (e) => {
      setLocalResults({
        ...localResults,
        competitions: localResults.competitions.map((comp) => {
          if (comp.id === id) {
            comp.value = Number(e.target.value)
          }
          return comp
        })
      })
    }
  };

  const calculateResults = (result) => {
    const parentResult = {
      kpi_id: result.kpi_id,
      value: result.value,
      comment: result.story.description ? result.story.description : "",
    };

    if (result.child_results.length === 0) {
      return [parentResult];
    } else {
      const childResults = result.child_results.map(child_result => ({
        kpi_id: child_result.kpi_id,
        value: child_result.value,
        comment: child_result.story ? child_result.story.description : "",
      }));
      return [parentResult, ...childResults];
    }
  }

  const handleSubmitResults = () => {
    const resultsToSubmit = {
      results: [
        ...localResults.results.map(calculateResults).flat(),
        ...localResults.competitions.map(competition => ({
          competition_id: competition.competition.id,
          value: competition.value,
        }))
      ],
      close_stage: false
    };
    props.updateMyResults({ teamId: team, data: resultsToSubmit });
  };

  const handleCloseResults = () => {
    const resultsToSubmit = {
      results: [
        ...localResults.results.map(calculateResults).flat(),
        ...localResults.competitions.map(competition => ({
          competition_id: competition.competition.id,
          value: competition.value,
        }))
      ],
      close_stage: true
    };
    props.updateMyResults({ teamId: team, data: resultsToSubmit });
  };

  return (
    <Grid container spacing={2} columns={16} className={classes.root} >
      <Grid item sx={{ width: "100%" }} >
        <Paper className={classes.paper} sx={{ height: "100%" }}>

          {/* Результаты */}
          {<Grid container className={classes.resultsContainerMedia} columns={16} sx={{ justifyContent: "space-between" }}>
            <Grid container direction="row" sx={{ justifyContent: "flex-start", width: "fit-content" }}>
              <Grid item>
                <Typography variant="h5" mb={2} sx={{ fontWeight: "700" }}>Результаты</Typography>
                {props.myResults.closed_at === 0 && <Typography variant='body2' color='#9196A8'>{team && !props.loading ? "До закрытия:" : ""}</Typography>}
                {team && !props.loading && <Grid container>
                  <Typography variant='h5' fontWeight='500'>
                    {props.myResults.closed_at === null ? moment(localResults.stage_closes_in).format('HHч mmм') : moment(localResults.closed_at).format('Закрыт в HH:mm')}
                  </Typography>
                  <Typography variant='body2' color='#9196A8' sx={{ ml: 2, lineHeight: "2.8em" }}>
                    {localResults.interim_at ?
                      `${moment(localResults.interim_at).format('Промежуточный итог в HH:mm')}`
                      : null}
                  </Typography>
                </Grid>}
              </Grid>
            </Grid>

            <Grid container className={classes.selectContainerMedia} sx={{ width: "fit-content" }}>
              {team === "" &&
                <Grid item mr={2} sx={{ width: "fit-content", display: "flex", alignItems: "center" }}>
                  <Typography>Выберите команду &nbsp;</Typography>
                  <ArrowForward />
                </Grid>}
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Команда</InputLabel>
                  <Select className={classes.selectMedia}
                    sx={{ width: "170px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={team}
                    label="Команда"
                    onChange={handleChangeTeam}
                  >
                    {localResultsTeams?.map((team) => {
                      return (
                        <MenuItem key={team.id} value={team.id}>{team.title}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item className={classes.buttonsContainerMedia} ml={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Stack spacing={3} className={classes.selectMedia} sx={{ width: "170px" }}>
                    <DesktopDatePicker
                      label="Дата"
                      inputFormat="DD/MM/YYYY"
                      value={date}
                      onChange={handleChangeDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>}

          {/* Конкурс */}
          {team && !props.loading && localResults.competitions?.length !== 0 && <Divider textAlign="left" sx={{ color: '#9196A8' }}>Конкурс</Divider>}
          {team && !props.loading && <Grid container spacing={2} columns={16} mb={2} mt={0.1}>
            {localResults.competitions?.map((comp) => {
              return (
                <Grid item xl={5.3} lg={8} md={16} sm={16} xs={16} key={comp.id} >
                  <Grid item
                    sx={{
                      border: "1px solid #EEEEEE", p: "12px",
                      boxShadow: "1px 1px 6px 0px rgba(54, 94, 124, 0.2)",
                      borderRadius: "5px",
                      width: "auto",
                    }}
                  >
                    <Typography variant='h5' color='#A734F5' fontWeight='700' sx={{ mb: 2 }}>{comp.competition.kpi_title}</Typography>
                    <Grid container>
                      <TextField value={comp.value} onChange={competitionChangeHandler(comp.id)} id="standard-basic" label="Факт"
                        variant="standard" InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>}

          {/* Сообщение в ленту */}
          {team && !props.loading && localResults.messages?.length !== 0 && <Divider textAlign="left" sx={{ color: '#9196A8' }}>Сообщения в ленту</Divider>}
          {team && !props.loading && <Grid container spacing={2} columns={16} mb={2} mt={0.1}>
            {localResults.messages?.map((message) => {
              return (
                <Grid item xl={5.3} lg={8} md={16} sm={16} xs={16} key={message.id}
                  onClick={() => {
                    setModalDataMessage(message);
                    setOpenMessageModal(true);
                  }}
                >
                  <Grid item
                    sx={{
                      border: "1px solid #EEEEEE",
                      p: "12px",
                      cursor: "pointer",
                      boxShadow: "1px 1px 6px 0px rgba(54, 94, 124, 0.2)",
                      borderRadius: "5px",
                      height: "118px"
                    }}
                  >
                    <Grid container>
                      <AvatarGroup max={2} sx={{ mr: 1, alignSelf: "start" }}>
                        <Avatar alt={message.message} src={message.avatar}
                          sx={{
                            width: 24,
                            height: 24,
                            alignSelf: "end"
                          }}
                        />
                      </AvatarGroup>
                      <Typography variant='body1' fontWeight={500} sx={{ mb: 2 }}>{message.first_name} {message.last_name}</Typography>
                    </Grid>
                    <Typography variant='body2'
                      sx={{
                        mb: 2,
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2
                      }}>
                      {message.message}
                    </Typography>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>}

          {/* Задачи на день */}
          {team && !props.loading && localResults.results?.filter((r) => !r.long_kpi).length !== 0 && <Divider textAlign="left" sx={{ color: '#9196A8' }}>Задачи на день</Divider>}
          {team && !props.loading && <Grid container spacing={2} columns={16} mb={2} mt={0.1}>
            {localResults.results?.filter((r) => !r.long_kpi).map((result) => {
              return (
                <Grid item xl={5.3} lg={8} md={16} sm={16} xs={16} key={result.id} >
                  <Grid container
                    sx={{
                      border: "1px solid #EEEEEE",
                      p: "12px", boxShadow: "1px 1px 6px 0px rgba(54, 94, 124, 0.2)",
                      borderRadius: "5px"
                    }}
                  >
                    <Grid container columns={16} sx={{ justifyContent: "space-between" }}>
                      <Grid item sx={{ display: "flex" }}>
                        <Typography variant='h5' color='#92D047' fontWeight='700' sx={{ mb: 2 }}>{result.kpi_title}</Typography>
                        {result.child_results.length > 0 && <span className={classes.numberCircle}>{result.child_results.length}</span>}
                      </Grid>
                      {result.child_results.length === 0 ? null :
                        <Button onClick={() => {
                          setModalDataKpi(result);
                          setOpenKpiModal(true);
                        }}>
                          <ArrowRightIcon sx={{ cursor: "pointer", color: "black" }} />
                        </Button>
                      }
                    </Grid>
                    <Grid container sx={{ justifyContent: "space-between" }}>
                      <Grid container className={classes.inputsContainerMedia} sx={{ width: "auto" }}>
                        <TextField disabled={true} value={result.plan} id="standard-basic" label="План"
                          variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ style: { color: 'rgba(0, 0, 0, 0.6)' }, }}
                          sx={{ width: "90px" }} className={classes.disabledInputBlack}
                        />
                        {result.child_results.length === 0 ?
                          <TextField value={result.value} onChange={resultChangeHandler(result.id, "value")} id="standard-basic" label="Факт"
                            variant="standard" InputProps={{ disableUnderline: true }} sx={{ width: "120px" }}
                          /> :
                          <TextField disabled={true} value={result.child_results.reduce((sum, child_result) => sum + child_result.value, 0)} id="standard-basic" label="Факт"
                            variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ style: { color: 'rgba(0, 0, 0, 0.6)' }, }}
                            sx={{ width: "90px" }} className={classes.disabledInputBlack}
                          />
                        }
                        <AvatarGroup className={classes.awardMedia} max={4} sx={{ ml: 1 }}>
                          {result.awards?.map((award) => {
                            return (
                              <Avatar className={classes.awardMedia} alt={award.description} src={award.image_url} key={award.id}
                                sx={{
                                  width: 24,
                                  height: 24,
                                  alignSelf: "end"
                                }}
                              />
                            )
                          })}
                        </AvatarGroup>
                      </Grid>
                      <Grid container sx={{ width: "fit-content", alignSelf: "end" }}>
                        <Typography variant='body2' >
                          {result.story?.comments_count !== 0 && result.story?.comments_count}
                        </Typography>
                        <Grid item mx={1} sx={{ color: "#9196A8", cursor: "pointer" }}
                          onClick={() => {
                            setModalDataKpiComment(result);
                            setOpenCommentKpiModal(true);
                          }}>
                          {!result.story.description || (result.story?.description === "Комментарий отсутствует." || "" || null) ?
                            <ChatBubbleOutlineIcon /> : <ChatBubbleIcon />}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>}

          {/* Задачи на месяц */}
          {team && !props.loading && localResults.results?.filter((r) => r.long_kpi).length !== 0 && <Divider textAlign="left" sx={{ color: '#9196A8' }}>Задачи на месяц</Divider>}
          {team && !props.loading && <Grid container spacing={2} columns={16} mb={2} mt={0.1}>
            {localResults.results?.filter((r) => r.long_kpi).map((result) => {
              return (
                <Grid item xl={5.3} lg={8} md={16} sm={16} xs={16} key={result.id}>
                  <Grid container
                    sx={{
                      border: "1px solid #EEEEEE",
                      p: "12px",
                      boxShadow: "1px 1px 6px 0px rgba(54, 94, 124, 0.2)",
                      borderRadius: "5px"
                    }}
                  >
                    <Grid container sx={{ justifyContent: "space-between" }}>
                      <Typography variant='h5' color='#2F80ED' fontWeight='700' sx={{ mb: 2 }}>{result.kpi_title}</Typography>
                    </Grid>
                    <Grid container columns={16} sx={{ justifyContent: "space-between" }}>
                      <Grid container className={classes.inputsContainerMedia} sx={{ width: "auto" }}>
                        <TextField disabled={true} value={result.month_target} id="standard-basic" label="План"
                          variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ style: { color: 'rgba(0, 0, 0, 0.6)' }, }}
                          sx={{ width: "120px" }} className={classes.disabledInputBlack}
                        />
                        <TextField value={result.value} onChange={resultChangeHandler(result.id, "value")} id="standard-basic" label="Факт"
                          variant="standard" InputProps={{ disableUnderline: true }} sx={{ width: "120px" }}
                        />
                        <AvatarGroup className={classes.awardMedia} sx={{ ml: 1 }}>
                          {result.awards?.map((award) => {
                            return (
                              <Avatar key={award.id} alt={award.description} src={award.image_url} sx={{ width: 24, height: 24, alignSelf: "end" }} />
                            )
                          })}
                        </AvatarGroup>
                      </Grid>
                      <Grid container sx={{ width: "fit-content", alignSelf: "end" }}>
                        <Typography variant='body2' >
                          {result.story?.comments_count !== 0 && result.story?.comments_count}
                        </Typography>
                        <Grid item mx={1} sx={{ color: "#9196A8", cursor: "pointer" }}
                          onClick={() => {
                            setModalDataKpiComment(result);
                            setOpenCommentKpiModal(true);
                          }}>
                          {!result.story.description || (result.story?.description === "Комментарий отсутствует." || "" || null) ? <ChatBubbleOutlineIcon /> : <ChatBubbleIcon />}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}

          </Grid>}
        </Paper>
      </Grid>

      {/* footer */}
      {(props.myResults.closed_at === null) && team && !props.loading &&
        <Paper sx={{
          ml: 2,
          width: "100%",
          mt: 2,
          position: 'sticky',
          bottom: 0,
          p: 2
        }} variant="outlined">
          <Box className={classes.buttonContainer}
            sx={{
              flexGrow: 1,
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}>
            {/* <Grid container>
            <Button className={classes.buttonMedia}
              onClick={handleOpenCommentModal}
              size="md"
              variant="outlined"
              startIcon={<ChatBubbleIcon />}
            >Написать комментарий дня
            </Button>
          </Grid> */}
            <Grid container className={classes.buttonContainerMedia} >
              <Button className={classes.buttonMedia}
                onClick={handleSubmitResults}
                size="md"
                variant="outlined"
              >Выслать промежуточный результат
              </Button>
              <Button sx={{ marginLeft: "12px" }} className={classes.buttonMedia}
                onClick={handleCloseResults}
                size="md"
                variant="contained"
              >Закрыть день
              </Button>
            </Grid>
          </Box>
        </Paper>}

      {/* modals */}
      {modalDataKpi != null ? <ModalKpi result={modalDataKpi} setModalDataKpi={setModalDataKpi} open={openKpiModal} close={handleCloseKpiModal} childChangeValue={resultChildValueChangeHandler} childChangeComment={resultChildCommentChangeHandler} /> : null}
      {modalDataKpiComment != null ? <ModalKpiComment result={modalDataKpiComment} setModalDataKpiComment={setModalDataKpiComment} open={openCommentKpiModal} close={handleCloseCommentKpiModal} changeComment={commentChangeHandler} /> : null}
      {modalDataMessage != null ? <ModalMessage message={modalDataMessage} setModalDataMessage={setModalDataMessage} open={openMessageModal} close={handleCloseMessageModal} /> : null}
      <ModalComment open={openCommentModal} close={handleCloseCommentModal} />

      {/* snackbar */}
      {snackbar.open && <div>
        <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>}

    </Grid>
  )
}

const mapStateToProps = (state) => ({
  myResults: state.myResultsReducer.myResults,
  loading: state.myResultsReducer.loading,
  myResultsTeams: state.myResultsTeamsReducer.myResultsTeams,
  loadingTeams: state.myResultsTeamsReducer.loading,
  requestStatus: state.myResultsReducer.requestStatus,
  snackbar: state.snackbarReducer,
})

const mapDispatchToProps = (dispatch) => ({
  loadMyResults: (query) => dispatch(getMyResultsRequest(query)),
  updateMyResults: (query) => dispatch(updateMyResultsRequest(query)),
  loadMyResultsTeams: (query) => dispatch(getMyResultsTeamsRequest(query)),
  updateMyResultsTeams: (query) => dispatch(updateMyResultsTeamsRequest(query)),
  showSnackbar: (open, message, severity) => dispatch(showSnackbar(open, message, severity)),
  hideSnackbar: () => dispatch(hideSnackbar()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyResultsPanel)
