import { initialMyResultsTeams } from "../initial/initialMyResultsTeams"
import { TypeKeys } from "../actions/actionTypes"

export const myResultsTeamsReducer = (state = initialMyResultsTeams(), action) => {
	switch (action.type) {
		case TypeKeys.GET_MYRESULTSTEAMS_REQUEST:
			return { ...state, myResultsTeams: [], loading: true }
		case TypeKeys.GET_MYRESULTSTEAMS_SUCCESS:
			return { ...state, myResultsTeams: action.myResultsTeams, loading: false }
		case TypeKeys.UPDATE_MYRESULTSTEAMS_REQUEST:
			return { ...state, myResultsTeams: [], loading: true }
		case TypeKeys.UPDATE_MYRESULTSTEAMS_SUCCESS:
			return { ...state, myResultsTeams: action.myResultsTeams, loading: false }
		default:
		return { ...state }
	}
}

export default myResultsTeamsReducer