import { Grid, Typography, Button, Box, Modal, Avatar, AvatarGroup } from '@mui/material';
// import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
// import ThumbUpIcon from '@mui/icons-material/ThumbUp';
// import ThumbDownIcon from '@mui/icons-material/ThumbDown';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: 400,
	bgcolor: 'background.paper',
	borderRadius: '5px',
	boxShadow: 24,
	padding: "16px 24px 8px 24px",
	alignItems: 'center',
};

export const ModalMessage = (props) => {
	return (
		<Modal
			open={props.open}
			onClose={() => { props.close(); props.setModalDataMessage(null); }}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography variant="h5" component="h2" sx={{ fontWeight: "700", mb: 2 }}>
					Сообщение в ленту
				</Typography>
				<Grid container sx={{ alignItems: "center" }}>
					<AvatarGroup max={2} sx={{ mr: 1, alignSelf: "start" }}>
						<Avatar
							alt={props.message.message} src={props.message.avatar}
							sx={{
								width: 24,
								height: 24,
							}}
						/>
					</AvatarGroup>
					<Typography id="modal-modal-title" variant="body1" sx={{ fontWeight: 500, fontSize: 14 }}>
						{props.message.first_name} {props.message.last_name}
					</Typography>
				</Grid>
				<Typography id="modal-modal-description" variant="body2" sx={{ my: 2 }}>
					{props.message.message}
				</Typography>
				{/* <Grid container>
					<ThumbUpIcon color="grey" sx={{color:"#9196A8"}}/>
					<Typography variant='body2' color='#9196A8' sx={{ml: 1}}>{"12"}</Typography>
					<ThumbDownIcon sx={{ml: 4, color:"#9196A8"}}/>
					<Typography variant='body2' color='#9196A8' sx={{ml: 1}}>{"8"}</Typography>
					<ChatBubbleIcon sx={{ml: 4, color:"#9196A8"}}/>
					<Typography variant='body2' color='#9196A8' sx={{ml: 1}}>{"42"}</Typography>
				</Grid> */}
				<Grid container sx={{ display: "flex", flexDirection: "row-reverse", mt: 2 }}>
					<Button
						onClick={() => { props.close(); props.setModalDataMessage(null); }}
						size="md"
						variant="text"
					>Закрыть
					</Button>
				</Grid>
			</Box>
		</Modal>
	)
}
